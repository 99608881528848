export default [
    {
        key:"entity_name",
        label:"Entity Name",
        visible:true,
        sortable: true
    },
    {
        key:"entity_num",
        label:"Entity Num",
        visible:true,
        sortable: true
    },
    {
        key:"court",
        label:"Court",
        visible:true,
        sortable: true
    },
    {
        key:"case",
        label:"Case #",
        visible:true,
        sortable: true
    },
    {
        key:"plaintiff",
        label:"Plantiff",
        visible:true,
        sortable: true
    },
    {
        key:"debt",
        label:"Debt",
        visible:true,
        sortable: true
    },
    {
        key:"address",
        label:"Address",
        visible:true,
        sortable: true
    },
    {
        key:"city",
        label:"City",
        visible:true,
        sortable: true
    },
    {
        key:"state",
        label:"State",
        visible:true,
        sortable: true
    },
    {
        key:"zip_code",
        label:"Zip",
        visible:true,
        sortable: true
    },
    {
        key:"courrier_types",
        label:"Type",
        visible:true,
        sortable: true,
    },
    {
        key:"track_number",
        label:"Track Number",
        visible:true,
        sortable: true,
    },
    {
        key: "seen_pdf",
        label:"File",
        thClass: "text-center",
        visible:true
    },
    {
        key:"created_at",
        label:"Creation Date",
        visible:true,
        sortable: true
    },
    {
        key:"sent_at",
        label:"Sent by",
        visible:true,
        sortable: true
    }
    
]

