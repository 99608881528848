const { amgApi } = require('@/service/axios')

class MarketingLettersService {
  async getMarketingLetters(params) {
    const data = await amgApi.post('/correspondence/get-marketing-letters', params)
    return data
  }

  async updateMarketingLetters(params) {
    const data = await amgApi.post('/correspondence/update-marketing-letters', params)
    return data
  }
  async updateMarketingLettersTracking(params) {
    const data = await amgApi.post('/correspondence/update-marketing-letters-tracking', params)
    return data
  }
  async sendMarketingLetters(params) {
    const data = await amgApi.post('/correspondence/send-marketing-letters', params)
    return data
  }

  async updateSeenPdf(params){
    try {
        const data = await amgApi.post("/correspondence/update-seen-pdf",params, {
            responseType: "blob",
        });
        return data;
    } catch (error) {
        throw error;
    }
  }

  async importMarketingLettersExcel(request){
    const data = await amgApi.post("/correspondence/import-marketing-letters-excel", request)
    return data
  }

  async exportMarketingLettersExcel(info) {
    const data = await amgApi.post('/correspondence/export-marketing-letters-excel', info, {
      responseType: 'blob',
    })
    return data.data
  }

  async getAllShipmentTypes(){

    const data = await amgApi.post('/correspondence/get-all-shipment-types')
    return data.data
  }
}

export default new MarketingLettersService()
