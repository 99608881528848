import { render, staticRenderFns } from "./Sent.vue?vue&type=template&id=73c5a5ff&scoped=true"
import script from "./Sent.vue?vue&type=script&lang=js"
export * from "./Sent.vue?vue&type=script&lang=js"
import style0 from "./Sent.vue?vue&type=style&index=0&id=73c5a5ff&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73c5a5ff",
  null
  
)

export default component.exports