<template>
  <div class="border-top-info border-3 border-table-radius px-0">
    <!-- Sent {{ $route.meta.sentTab }} -->
    <filter-slot
      :filterPrincipal="filterPrincipal"
      :filter="filter"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="refresh"
    >
      <template #table>
        <b-table
          slot="table"
          ref="refTableList"
          small
          :items="getAllData"
          :fields="visibleFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          class="overflow-y"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(debt)="data">
            $ {{ data.item.debt | currency }}
          </template>
          <template #cell(created_at)="data">
            <div
              class="d-flex flex-column justify-content-start align-items-start"
            >
              <span>
                {{ data.item.created_at | myGlobalDay }}
              </span>
            </div>
          </template>
          <template #cell(seen_pdf)="data">
            <!-- {{data.item}} -->
            <div class="d-flex justify-content-center">
              <a
                :class="
                  data.item.seen_pdf == 0 ? 'float-left' : 'opa float-left'
                "
                class="text-center"
                target="_blank"
                @click="openPdf(data.item)"
              >
                <amg-icon
                  class="cursor-pointer bigger text-danger"
                  icon="FilePdfIcon"
                />
              </a>
            </div>
          </template>
          <template #cell(sent_at)="data">
            <span>
              {{ data.item.full_name }} <br />
              {{ data.item.sent_at | myGlobalDay }}
            </span>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </div>
</template>

<script>
import fields from "../data/fields.sent.data";
import filters from "../data/filters.sent.data";
import MarketingLettersService from "../../service/marketing-letters.service";
export default {
  data() {
    return {
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      sortBy: "sent_at",
      sortDesc: true,
      fields: fields,
      startPage: null,
      endPage: null,
      nextPage: null,
      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      filter: filters,
    };
  },
  created() {
    if (this.getAgentType === 5 || this.getAgentType === 6) {
      this.fields.find(
        (element) => element.key === "entity_num"
      ).visible = false;
    } else {
      this.fields.find(
        (element) => element.key === "entity_num"
      ).visible = true;
    }
    this.fields.find((element) => element.key === "court").visible =
      this.getAgentType === 6;
    this.fields.find((element) => element.key === "case").visible =
      this.getAgentType === 6;
    this.fields.find((element) => element.key === "plaintiff").visible =
      this.getAgentType === 6;
    this.fields.find((element) => element.key === "debt").visible =
      this.getAgentType === 6;
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    getAgentType() {
      return this.$route.meta.sentTab ==
        "correspondence-marketing-letters-corporate-agent-sent"
        ? 4
        : this.$route.meta.sentTab == "correspondence-marketing-letters-keybook-sent" ? 5 : 6;
    },
  },
  methods: {
    async openPdf(data) {
      try {
        this.addPreloader();
        const result = await MarketingLettersService.updateSeenPdf({
          data: data,
        });
        if (result) {
          this.forceFileDownload(result.data, "document.pdf", "blank");
          this.removePreloader();
          this.refresh();
        } else {
          this.removePreloader();
          this.showErrorSwal();
        }
      } catch (error) {
        console.error(error);
        this.removePreloader();
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong!"
        );
      }
    },
    async getAllData(ctx) {
      try {
        const params = {
          name_text: this.filterPrincipal.model,
          from: this.filter[0].model,
          to: this.filter[1].model,
          orderby: ctx.sortBy,
          order: ctx.sortDesc ? "desc" : "asc",
          perpage: this.paginate.perPage,
          page: this.paginate.currentPage,
          view_pdf: null,
          courrier_type: null,
          agent_type: this.getAgentType,
          tab_type: 2,
        };
        const response = await MarketingLettersService.getMarketingLetters(
          params
        );
        this.startPage = response.data.from;
        this.paginate.currentPage = response.data.current_page;
        this.paginate.perPage = response.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = response.data.last_page;
        this.totalRows = response.data.total;
        this.toPage = response.data.to;
        return response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    refresh() {
      this.$refs.refTableList.refresh();
    },
  },
};
</script>

<style scoped>
.opa {
  opacity: 0.5;
}
</style>
